<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                  <b-col>
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                        {{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}
                      </list-report-head>
                  </b-col>
              </b-row>
              <!-- <b-row id="form">
                <b-col lg="12" sm="12">
                  <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.personal_info')}}</legend>
                  <b-table-simple class="tg mt-3" hover small caption-top>
                    <tbody>
                      <tr>
                        <th style="width: 20%"  class="text-left">{{ $t('globalTrans.name') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 27%">{{ $i18n.locale === 'bn' ? formData.name_bn : formData.name }}</td>
                        <th style="width: 20%"  class="text-left">{{ $t('globalTrans.organization') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 27%">{{ $i18n.locale === 'bn' ? formData.professional_other_org_name_bn : formData.professional_other_org_name }}</td>
                      </tr>
                      <tr>
                        <th style="width: 20%"  class="text-left">{{ $t('globalTrans.mobile') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 27%">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.mobile, { useGrouping:false }) }}</td>
                        <th style="width: 20%"  class="text-left">{{ $t('globalTrans.email') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 27%">{{ formData.email }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                    </fieldset>
                </b-col>
              </b-row> -->
              <b-overlay :show="load">
                <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tim.trainee') + ' ' + $t('globalTrans.details') }}</legend>
                      <b-table-simple class="tg mt-3" hover small caption-top>
                        <tbody>
                          <tr>
                            <th style="width: 20%"  class="text-left">{{ $t('globalTrans.fiscal_year') }}</th>
                            <th style="width: 3%" class="text-center">:</th>
                            <td style="width: 27%">{{ $i18n.locale === 'bn' ? item.fiscal_year_bn : item.fiscal_year }}</td>
                            <th style="width: 20%"  class="text-left">{{ $t('globalTrans.organization') }}</th>
                            <th style="width: 3%" class="text-center">:</th>
                            <td style="width: 27%">{{ $i18n.locale === 'bn' ? item.org_bn : item.org }}</td>
                          </tr>
                          <tr>
                            <th style="width: 20%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                            <th style="width: 3%" class="text-center">:</th>
                            <td style="width: 27%">{{ $i18n.locale === 'bn' ? item.training_type_bn : item.training_type }}</td>
                            <th style="width: 20%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                            <th style="width: 3%" class="text-center">:</th>
                            <td style="width: 27%">{{ $i18n.locale === 'bn' ? item.training_category_bn : item.training_category }}</td>
                          </tr>
                          <tr>
                            <th style="width: 20%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                            <th style="width: 3%" class="text-center">:</th>
                            <td style="width: 27%">{{ $i18n.locale === 'bn' ? item.training_title_bn : item.training_title }}</td>
                            <th style="width: 20%"  class="text-left">{{ $t('elearning_iabm.circular_memo_no') }}</th>
                            <th style="width: 3%" class="text-center">:</th>
                            <td style="width: 27%">{{ $i18n.locale === 'bn' ? item.circular_memo_no : item.circular_memo_no }}</td>
                          </tr>
                          <tr>
                            <th style="width: 20%"  class="text-left">{{ $t('elearning_iabm.batch_no') }}</th>
                            <th style="width: 3%" class="text-center">:</th>
                            <td style="width: 27%">{{ $i18n.locale === 'bn' ? item.batch_bn : item.batch }}</td>
                            <th style="width: 20%"  class="text-left"></th>
                            <th style="width: 3%" class="text-center"></th>
                            <td style="width: 27%"></td>
                          </tr>
                        </tbody>
                      </b-table-simple>
                    </fieldset>
                  </div>
                </b-row>
                <b-row>
                  <b-col md="6" class="mb-2">
                    <b-form-checkbox
                      style="margin-top: 5px"
                      class="mb-3"
                      name="checked"
                      v-model="selectAll"
                      @change="checkAll"
                      :value=true
                      :unchecked-value=false
                    >
                    <span>{{ $t('globalTrans.checkAll') }}</span>
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="mb-2 text-right">
                    <button @click="createData()" class="btn btn-success btn-sm mr-2" :disabled="selectedItem.length > 0 ? false : true">
                      <i class="fas fa-list"></i> {{ $t('globalTrans.approve') }}
                    </button>
                  </b-col>
                </b-row>
                <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.trainee') + ' ' +$t('globalTrans.list')}}</legend>
                      <table class="table" style="width:100%;padding: 5px">
                        <thead class="thead">
                          <tr>
                            <th style="width:15%">{{ $t('globalTrans.sl_no') }}</th>
                            <th style="width:12%">{{$t('globalTrans.name')}}</th>
                            <th style="width:17%">{{$t('globalTrans.designation')}}</th>
                            <th style="width:17%">{{$t('elearning_tpm.work_place')}}</th>
                            <th style="width:20%">{{$t('globalTrans.mobile')}} </th>
                            <th style="width:20%">{{$t('globalTrans.email')}} </th>
                            <th style="width:20%">{{$t('globalTrans.action')}} </th>
                          </tr>
                        </thead>
                        <tr v-for="(item, index) in trainee" :key="index">
                          <b-td>
                            {{ $n(index + 1) }}
                          </b-td>
                          <b-td>
                            {{ ($i18n.locale=='bn') ? item.name_bn : item.name}}
                          </b-td>
                          <b-td>
                            <span v-if="item.designation_id && item.not_here_designation == 0 && item.profession_type == 1">{{ getDesignation(item.designation_id) }}</span>
                            <span v-else>{{ ($i18n.locale=='bn') ? item.designation_bn : item.designation_en}}</span>
                          </b-td>
                          <b-td>
                            <span v-if="parseInt(item.profession_type) === 1 && item.not_here_office === 1">{{ (($i18n.locale=='bn') ? item.office_name_bn : item.office_name) }}</span>
                            <span v-if="parseInt(item.profession_type) === 1 && item.not_here_office === 0">{{ getOfficeName(item.professional_office_id) + ', ' + getOrgName(item.professional_org_id) }}</span>
                            <span v-if="parseInt(item.profession_type) !== 1">{{ ($i18n.locale=='bn') ? item.professional_other_org_name_bn : item.professional_other_org_name }}</span>
                          </b-td>
                          <b-td>
                            {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(item.mobile, { useGrouping: false }) }}
                          </b-td>
                          <b-td>
                            {{ item.email }}
                          </b-td>
                          <b-td class="text-center">
                              <b-form-checkbox-group v-model="selectedItem" style="margin-top: 5px">
                                <b-form-checkbox :disabled="(item.sort_status == 3) ? false : true" :selected="(item.sort_status == 3 ) ? false : true" :value="item.id" style="margin-right: 0"></b-form-checkbox>
                              </b-form-checkbox-group>
                          </b-td>
                        </tr>
                      </table>
                    </fieldset>
                  </div>
                  <!-- <pre>{{ trainee }}</pre> -->
                </b-row>
              </b-overlay>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { trainingTitleModule, allRejectedTrainee, trainerSortingStore } from '../../api/routes'
import Store from '@/store'

export default {
    components: {
        ListReportHead
    },
  props: ['item'],
  created () {
    if (this.item) {
      this.getTraineeData()
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      trainee: [],
      selectAll: false,
      selectedItem: [],
      load: false,
      officeTypeList: [],
      courses: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
    watch: {
      selectedItem: function () {
        if (this.selectedItem.length === this.trainee.length) {
          this.selectAll = true
        }
        if (this.selectedItem.length < this.trainee.length) {
          this.selectAll = false
        }
      }
    },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
      checkAll () {
        if (this.selectAll) {
          this.selectedItem.splice(0, this.selectedItem.length)
          this.trainee.filter(el => (el.sort_status === 3)).forEach(element => this.selectedItem.push(element.id))
        } else {
          this.selectedItem.splice(0, this.selectedItem.length)
        }
      },
      async createData () {
        const obj = {
          selected_itm: this.selectedItem
        }
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        let result = null
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerSortingStore, obj)
        if (result.success) {
          this.getTraineeData()
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
        } else {
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      },
    getDesignation (id) {
      const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return designation !== undefined ? designation.text_bn : ''
      } else {
        return designation !== undefined ? designation.text_en : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    getOrgName (id) {
      const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getTrainingTypeName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTrainingTitleName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTrainingCatName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getCourseModuleName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getCourseName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.courseList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    async getCircularMemoNo (trainingTitleId) {
      if (trainingTitleId) {
        this.load = true
        const searchData = {
          training_title_id: this.formData.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingTitleModule, searchData)
        if (!result.success) {
          this.courses = []
        } else {
            this.courses = result.data
        }
        this.load = false
      }
    },
    async getTraineeData () {
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, allRejectedTrainee, this.item)
        if (!result.success) {
          this.trainee = []
        } else {
            this.trainee = result.data
        }
        this.load = false
    },
    // getFormData () {
    //   const tmpData = this.$store.state.list.find(item => item.id === this.id)
    //   return JSON.parse(JSON.stringify(tmpData))
    // },
    getRoomRentList (typeId) {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1 && item.room_type_id === typeId)
    },
    getRoomList (typeId) {
      const roomlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = roomlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.room_no, value: item.room_no }
        } else {
          return { text: item.room_no, value: item.room_no }
        }
      })
      return tempData
    },
    getFloorList (typeId) {
      const floorlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = floorlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.floor_no, value: item.floor_no }
        } else {
          return { text: item.floor_no, value: item.floor_no }
        }
      })
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('globalTrans.approved') + ' ' + this.$t('elearning_tim.trainee') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
